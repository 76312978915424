import styles from './ScoreRing.module.css'
import clsx from 'clsx'
import { BasicStyledComponent } from 'shared-definitions/types'
import { uniqueIdString } from 'shared-code/util'
import { CSSProperties, useEffect, useState } from 'react'

interface ScoreRingProps extends BasicStyledComponent {
  score: number
  variant?: 'base' | 'violet'
}

const ScoreRing: React.FC<ScoreRingProps> = ({ children, className, score, variant }) => {
  const [gradId, setGradId] = useState<string | null>(null)
  useEffect(() => {
    setGradId(uniqueIdString())
  }, [])

  return (
    <div
      className={clsx(styles.container, className)}
      style={{ '--gradid': `url('#${gradId ?? 'a'}')` } as CSSProperties}
    >
      <svg
        viewBox="0 0 36 36"
        className={clsx(styles.icon, {
          [styles.variantBase]: !variant || variant === 'base',
          [styles.variantViolet]: variant === 'violet',
        })}
      >
        <defs>
          {gradId ? (
            <linearGradient id={gradId} x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="var(--grad-start)" />
              <stop offset="100%" stopColor="var(--grad-stop)" />
            </linearGradient>
          ) : null}
        </defs>
        <path
          className={styles.pathBg}
          d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          className={styles.pathFg}
          d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
          strokeDasharray={`${score * 10}, 100`}
        />
      </svg>
      {children ?? score}
    </div>
  )
}

export default ScoreRing
